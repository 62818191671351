@import 'styles/colors';
@import 'styles/general';

#app-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-self: end;
  flex: none;
  background: $off-white;
  box-shadow: $sm-box-shadow-reverse;

  .footer-content {
    max-width: 52rem;
    width: 100%;
    margin: 0 1.25rem;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
  }

  .footer-resources-header {
    color: $dark-blue;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .footer-resources-link {
    display: block;
    color: $blue-gray;
    text-decoration: none;
    margin-bottom: 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-copyright-container {
    display: flex;
    align-items: center;
  }

  .footer-logo {
    height: 2rem;
    margin-right: 1rem;
  }

  .footer-copyright {
    color: $dark-gray;
    line-height: 1;
    display: inline-block;
  }

  @media screen and (max-width: 800px) {
    .footer-content {
      margin: 0;
    }
  }

  @media screen and (max-width: 650px) {
    .footer-content {
      flex-direction: column;
    }

    .footer-content-left {
      margin-bottom: 1rem;
    }
  }
}
