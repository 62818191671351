@import 'styles/colors';
@import 'styles/general';

.last-non-profit-container {
  margin: 1rem 0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-non-profit-content {
  margin-right: 0.5rem;
}

.last-non-profit-header {
  margin: 0 0 0.75rem 0;
}

.last-non-profit {
  font-size: 1.125rem;
}

@media only screen and (max-width: 600px) {
  .last-non-profit-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .last-non-profit-content {
    margin-bottom: 1rem;
  }
}
