@import 'styles/colors';
@import 'styles/general';

.donation-item {
  display: block;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.donation-item-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid $white-gray;
  box-shadow: $sm-box-shadow;
  transition: all 120ms;

  &:hover {
    border-color: $light-blue;
  }
}

.donation-item-image-container {
  display: flex;
  align-items: center;
}

.donation-item-image {
  width: 128px;
  height: 96px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.5rem;
}

.donation-item-text-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donation-item-amount-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: stretch;
}

.donation-item-amount-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donation-item-amount-value {
  font-size: 3rem;
  color: #2ecc71;
}

.donation-item-amount-label {
  color: #222;
  padding: 0.5rem;
  text-align: center;
}

.donation-item-header {
  margin-top: 0.5rem;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .donation-item-body {
    flex-direction: column;
  }

  .donation-item-text-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .donation-item-image {
    width: 100px;
    height: 80px;
  }
}

.donation-list-loading-container {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
